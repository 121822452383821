import { v4 as uuidv4 } from 'uuid';

import { transformAddToCart } from './dataLayer';
import {
  GTMTrackingObject,
  TrackingEvent,
  TrackingObject,
  WebEventCategory,
  WebEventTrackingObject,
} from './types';
import { getClientSiteCode, getPageTypeClient } from '@Contexts/contexts';
import { getClientUserPreferences } from '@Contexts/UserPreferencesContext/UserPreferencesContext';
import { convertPenceToPounds } from '@Core/prices/magnitudes';

const pageviewTransformer = (event: TrackingObject): GTMTrackingObject => ({
  event: 'pageview',
  data: event,
});

const searchTransformer = (event: TrackingObject): GTMTrackingObject => ({
  event: 'searchAction',
  searchData: event,
  data: {
    pageType: event.pageType,
    siteCode: event.siteCode,
  },
});

const experimentTransformer = ({
  event,
  pageType,
  siteCode,
  ...rest
}: TrackingObject): GTMTrackingObject => ({
  event: 'Experiment',
  data: {
    pageType,
    siteCode,
  },
  ...rest,
});

const ecommerceViewTransformer = ({
  event,
  list,
  pageType,
  siteCode,
  masterId,
  ...rest
}: TrackingObject): GTMTrackingObject => ({
  event: 'eec.detail',
  data: {
    pageType,
    siteCode,
  },
  ecommerce: {
    detail: {
      list,
      products: [
        {
          id: masterId,
          masterId,
          ...rest,
        },
      ],
    },
  },
});

const productAddedTransformer = ({
  event,
  list,
  pageType,
  siteCode,
  ...rest
}: TrackingObject): GTMTrackingObject => ({
  event: 'eec.addToCart',
  data: {
    pageType,
    siteCode,
  },
  ecommerce: {
    add: {
      list,
      products: [
        {
          ...transformAddToCart({ ...rest, list }),
        },
      ],
    },
  },
});

const webEventTransformer = (webEvent: TrackingObject) => {
  if ((webEvent as unknown as WebEventTrackingObject).category === WebEventCategory.error) {
    return webEvent as unknown as GTMTrackingObject;
  }

  return null;
};

const confirmationEventTransformer = (confirmationEvent: TrackingObject): GTMTrackingObject => ({
  event: 'trackConf',
  confirmationData: {
    affiliationId: confirmationEvent.affiliation,
    checkInDate: confirmationEvent.products[0].checkInDate,
    checkOutDate: confirmationEvent.products[0].checkOutDate,
    city: confirmationEvent.products[0].city,
    currency: confirmationEvent.currency,
    destinationAirportCode: confirmationEvent.products[0].destinationAirportCode,
    destinationCountry: confirmationEvent.products[0].destinationCountry,
    expectedRevenue: confirmationEvent.revenue,
    hotelName: confirmationEvent.products[0].name,
    inboundArrvDate: confirmationEvent.products[0].inboundArrvDate,
    items: [
      {
        affiliation: confirmationEvent.affiliation,
        item_id: confirmationEvent.products[0].product_id,
        item_name: confirmationEvent.products[0].name,
        item_category: 'DYN_PACKAGE', // This can also be HOTEL_ONLY (update later - only DP right now)
        item_category2: confirmationEvent.products[0].destinationCountry,
        item_category3: confirmationEvent.products[0].resort,
      },
    ],
    lvhId: confirmationEvent.extraFieldsForGTM.lvhId,
    masterId: confirmationEvent.products[0].product_id,
    numberOfAdults: confirmationEvent.products[0].numberOfAdults,
    numberOfChildren: confirmationEvent.products[0].numberOfChildren,
    numberOfNights: confirmationEvent.products[0].numberOfNights,
    originAirportCode: confirmationEvent.products[0].originAirportCode,
    originAirportName: confirmationEvent.products[0].originAirportName,
    outboundAirline: confirmationEvent.products[0].outboundAirline,
    outboundDeptDate: confirmationEvent.products[0].outboundDeptDate,
    resort: confirmationEvent.products[0].resort,
    promoCode: confirmationEvent.coupon,
    total: confirmationEvent.products[0].price,
    transactionId: confirmationEvent.order_id,
    transactionTotal: confirmationEvent.revenue,
    webSellingPrice: confirmationEvent.extraFieldsForGTM.webSellingPrice,
    transactionReference: confirmationEvent.order_id,
  },
  // This is used for Criteo sales tracking
  products: [
    {
      category: 'HF',
      id: confirmationEvent.products[0].product_id,
      price: convertPenceToPounds(confirmationEvent.products[0].price),
      name: confirmationEvent.products[0].name,
      quantity: 1,
    },
  ],
  leadPassengerEmail: confirmationEvent.email,
  leadPassengerFirstName: confirmationEvent.firstName,
  leadPassengerLastName: confirmationEvent.lastName,
  leadPassengerPhone: confirmationEvent.phone,
  leadPassengerPostcode: confirmationEvent.postcode,
  pointOfSale: confirmationEvent.pointOfSale,
  enablePurchaseTracking: confirmationEvent.extraFieldsForGTM.enablePurchaseTracking,
  transactionId: confirmationEvent.order_id,
  transactionTotal: convertPenceToPounds(confirmationEvent.products[0].price),
  currencyCode: confirmationEvent.currency,
  revenueTotal: String(convertPenceToPounds(confirmationEvent.revenue as number)),
  ...(confirmationEvent.siteCode === 'HP' && {
    transactionAffiliation: 'loveholidays',
  }),
  experimentBucket: confirmationEvent.extraFieldsForGTM.experimentBucket,
});

const datalayerTransformer: Record<
  TrackingEvent,
  ((event: TrackingObject) => GTMTrackingObject | null) | null
> = {
  [TrackingEvent.pageView]: pageviewTransformer,
  [TrackingEvent.paymentPlans]: null,
  [TrackingEvent.productClick]: null,
  [TrackingEvent.productDetailsView]: ecommerceViewTransformer,
  [TrackingEvent.productAdded]: productAddedTransformer,
  [TrackingEvent.productRemoved]: null,
  [TrackingEvent.productPayment]: null,
  [TrackingEvent.formError]: null,
  [TrackingEvent.productImpression]: null,
  [TrackingEvent.searchChange]: searchTransformer,
  [TrackingEvent.alternativeFlightsChange]: null,
  [TrackingEvent.experiment]: experimentTransformer,
  [TrackingEvent.webEvent]: webEventTransformer,
  [TrackingEvent.destinationAutocomplete]: null,
  [TrackingEvent.identify]: null,
  [TrackingEvent.favourites]: null,
  [TrackingEvent.checkoutStarted]: null,
  [TrackingEvent.checkoutStepViewed]: null,
  [TrackingEvent.checkoutPartnerInteraction]: null,
  [TrackingEvent.checkoutStepCompleted]: null,
  [TrackingEvent.checkoutCompleted]: confirmationEventTransformer,
  [TrackingEvent.couponEntered]: null,
  [TrackingEvent.couponApplied]: null,
  [TrackingEvent.couponDenied]: null,
  [TrackingEvent.feedback]: null,
  [TrackingEvent.checkoutTimeIsUp]: null,
  [TrackingEvent.payment]: null,
  [TrackingEvent.travelRequirementsLinkUsed]: null,
  [TrackingEvent.map]: null,
  [TrackingEvent.userInteractionUnacknowledged]: null,
  [TrackingEvent.userInteractionAcknowledged]: null,
  [TrackingEvent.primerOnResumeSuccess]: null,
  [TrackingEvent.primerPaymentIdMissing]: null,
  [TrackingEvent.primerCardNetworkNotSupported]: null,
  [TrackingEvent.primerCheckoutMountMiss]: null,
  [TrackingEvent.popularFilterUsed]: null,
  [TrackingEvent.eventsProm]: null,
  [TrackingEvent.paymentFormViewed]: null,
  [TrackingEvent.paymentOptionsViewed]: null,
  [TrackingEvent.scriptLoadingFailed]: null,
  [TrackingEvent.stepperInteraction]: null,
  [TrackingEvent.addressSuggestionSelected]: null,
};

export async function sendEvent<T extends TrackingObject>(obj: T) {
  // Do not send tracking on SSR
  if (typeof window === 'undefined') {
    return;
  }

  const { event, id, ...eventObj } = obj;

  if (event === TrackingEvent.pageView) {
    window.pageID = uuidv4();
  }

  const { lvhChannel, cookiePreferences } = getClientUserPreferences() ?? {};

  const sendObjectBody = event !== TrackingEvent.identify;

  const canonicalHref =
    window.document.querySelector('link[rel=canonical]')?.getAttribute('href') ||
    window.location.href;
  const canonicalUrl = new URL(canonicalHref);
  canonicalUrl.search = canonicalUrl.search || window.location.search;
  const context = {
    page: {
      path: canonicalUrl.pathname,
      url: canonicalUrl.href.replace(/#.*/, ''),
      tab_url: window.location.href,
      search: window.location.search,
      title: document.title,
    },
  };

  const [siteCode, pageType, channel, cookies] = sendObjectBody
    ? [
        getClientSiteCode(),
        await getPageTypeClient(window.location.pathname),
        lvhChannel?.get(),
        cookiePreferences?.get(),
      ]
    : [];

  const objWithPageID: Omit<TrackingObject, 'event'> = {
    siteCode,
    pageType,
    channel,
    cookies,
    application: 'sunrise',
    ...eventObj,
    pageID: window.pageID,
  };

  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.debug('Event Debug', JSON.stringify(event), JSON.stringify(objWithPageID, null, 2));
  }

  // Sending the event once the event loop is free
  setTimeout(() => {
    if (window.rudderanalytics) {
      const tracker = window.rudderanalytics;
      switch (event) {
        case TrackingEvent.identify:
          tracker.identify(obj.userId, null, context);
          break;
        case TrackingEvent.webEvent:
          tracker.track(obj.category, objWithPageID, context);
          break;
        case TrackingEvent.pageView:
          tracker.setAnonymousId(objWithPageID.lvhId);
          tracker.page(objWithPageID, context);
          break;
        case TrackingEvent.checkoutCompleted: {
          const { extraFieldsForGTM, ...rudderEventPayload } = objWithPageID;
          tracker.track(event, rudderEventPayload, context);
          break;
        }
        default:
          tracker.track(event, objWithPageID, context);
      }
    }

    const datalayerTransform = datalayerTransformer[event];

    if (datalayerTransform && typeof datalayerTransform === 'function') {
      const dataLayerObj = {
        ...obj,
        id,
        pageID: window.pageID,
        siteCode,
        pageType,
        channel,
        cookies,
      };

      window.dataLayer = window.dataLayer || [];
      const transformedEvent = datalayerTransform(dataLayerObj);
      if (transformedEvent) {
        window.dataLayer.push(transformedEvent);
        if (process.env.NODE_ENV === 'development') {
          // eslint-disable-next-line no-console
          console.debug(
            'GTM Event Debug',
            JSON.stringify(event),
            JSON.stringify(transformedEvent, null, 2),
          );
        }
      }
    }

    /**
     * In some cases we need to wait until the tracking finishes before doing something else.
     * A good example is the product click tracking where we need to wait until the tracking
     * finishes before we navigate to the product page.
     * https://developers.google.com/tag-manager/enhanced-ecommerce#promo-clicks
     *
     * As the `eventCallback` parameter is called by GTM, it won't be executed when
     * GTM is not available on the page (e.g. during local development), so we should call
     * that event callback programmatically.
     */
    if (!window.google_tag_manager && objWithPageID.eventCallback) {
      objWithPageID.eventCallback();
    }
  }, 0);
}
